$black : #000;
$white : #fff;
$violet: #7644ff;
$violetLight: #7644ffd9;
$info: #3194ff;
$infoLight: #3194ffd9;
$warning: #ffc107;
$danger: #f95f5f;
$dangerLight: #f95d5db3;
$success: #20c997;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-600-40: #6c757d66;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$gray-900-60: #21252999;