.success-modal{
    position: fixed;
    width: 600px;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: $white;
    padding: 60px;
    border-radius: 6px;
    text-align: center;

    .title {
        font-size: 24px;
        font-weight: 300;
        margin-bottom: 32px;
    }

    .or-section {
        position: relative;
        margin: 16px 0;

        .or-seperator-line {
            position: absolute;
            height: 1px;
            width: 100%;
            background-color: $gray-500;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        .or-text {
            position: relative;
            display: inline-block;
            font-weight: 500;
            color: $gray-600;
            text-transform: uppercase;
            padding: 8px;
            background-color: $white;
            z-index: 3;
        }
    }
}

@include media-breakpoint-down(md) {     
    .success-modal{
        width: calc(100% - 40px);
    }
}