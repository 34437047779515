.top-section {  
    font-size: 24px;
    text-align: center;

    .title {
        font-weight: 300;
    }
    
    .sub-title {
        font-weight: 500;

        .job-title {
            color: $violet;
        }
    }

    img {
        width: 160px;
        height: auto;
    }

    p {
        margin: 32px 0 0;
        font-size: 16px;
        overflow: hidden;
        text-align: left;
        width: 100%;
        text-overflow: ellipsis;
        white-space: normal;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;

        &.extended {
            display: block;
            overflow: auto;
        }

        &.spacing{
            padding-bottom: 16px;
        }
    }  

    .show-more {
        font-size: 16px;
        font-weight: 500;
        color: $gray-600;
        text-align: right;
        cursor: pointer;
        transition: 0.3s ease-in;

        &:hover {
            color: $gray-900;
        }
    }
}