.form-wrapper {
    margin-bottom: 32px;
    
    .form-section{
        margin-bottom: 16px;

        .form-heading {
            font-size: 20px;
            font-weight: 500;
            margin-bottom: 16px;
        }
    }

    .form-group {
        margin-bottom: 16px;
    }

    .form-label {
        font-weight: 500;
    }

    .form-control, .form-select {
        &:focus {
            box-shadow: 0 0 0 2px $gray-900-60;
            border-color: $gray-100;
        }

        &.error {
            border-color: $danger;

            &:focus {
                box-shadow: 0 0 0 2px $dangerLight;
            }
        }
    }

    .form-check-input{
        &:checked {
            background-color: $violet;
            border-color: $violet;
        }

        &:focus {
            box-shadow: 0 0 0 1px $violetLight;
            border-color: $violetLight;
        }
    }

    .error-message {
        color: $danger;
        margin-left: 12px;
        display: inline-block;
    }

    .privacy-policy-link {
        color: $info;

        &:hover {
            color: $infoLight;
        }
    }

    .phone-input-wrapper {
        display: flex;

        .country-code-input {
            flex: 1;
            margin-right: 16px;
        }

        .phone-number-input {
            flex: 3;
        }
    }
}