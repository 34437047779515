@import "~bootstrap/scss/bootstrap";
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

@import "./css/variables";
@import "./css/topSection";
@import "./css/applyForm";
@import "./css/successModal";
@import "./css/loadingScreen";

.external-apply-page {
    width: 100%;

    &.no-scroll {
        height: 100vh;
        overflow-y: hidden;
    }

    .language-selector-wrapper {
        display: flex;
        justify-content: flex-end;
        margin: 16px 0;

        .globe-icon {
            padding-left: 12px;
        }
    }
}

.apply-page-modal-overlay {
    position: fixed;
    display: none; 
    width: 100%; 
    height: 100%; 
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $gray-600-40;

    &.show-modal {
        display: block;
    }
}

.external-apply-page, .apply-page-modal-overlay {
    color: $gray-900;
    font-family: "Roboto", sans-serif;
    
    .btn-wrapper {
        text-align: center;

        .btn-primary {
            background-color: $violet;
            border-color: $violet;
    
            &:hover, &:active {
                background-color: $violetLight;
                border-color: $violetLight;
            }
    
            &:focus {
                box-shadow: 0 0 0 2px $violetLight;
                border-color: $violetLight;
            }
        }
    }
}

@include media-breakpoint-down(sm) {     
    .external-apply-page {
        width: 100%; 
    } 
}


